import React from "react"
import { Link } from "gatsby"

import LaptopIcon from "../../../assets/icons/laptop.svg"
import MobileIcon from "../../../assets/icons/mobile.svg"
import LightbulbIcon from "../../../assets/icons/light-bulb.svg"

const ServiceSelection = () => (
  <section id="ServiceSelection" className="pb-5 my-5">
    <div className="container">
      <div className="row mb-4">
        <div className="col-lg-9 m-auto text-center">
          <h2 className="text-capitalize">Choose a service below</h2>
          <p className=" text-muted fw-l">
            Tactic Apps works with clients of all shapes and sizes. Let us guide
            you through the development process and help you build the best
            application within your budget.
          </p>
        </div>
      </div>
      <div className="row text-center pt-3">
        <div className="col-sm-6 col-md-4 mb-4">
          <Link to="/services/startup-solutions/">
            <div className="card shadow-sm">
              <div className="card-body p-4 text-decoration-none">
                <div className="text-center mb-3">
                  <LightbulbIcon />
                </div>
                <h5 className="text-uppercase">Startup Solutions</h5>
                <span className="text-muted">
                  Let us help you reduce your time and financial risk through
                  idea validation. From idea to MVP to fully polished product,
                  we partner with you every step of the way.
                </span>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-sm-6 col-md-4 mb-4">
          <Link to="/services/web-development/">
            <div className="card shadow-sm">
              <div className="card-body p-4">
                <div className="text-center mb-3">
                  <div className="text-center mb-3">
                    <LaptopIcon />
                  </div>
                </div>
                <h5 className="text-uppercase">Web Development</h5>
                <span className="text-muted">
                  Any browser, anywhere. We develop full-stack web apps that run
                  on all devices with modern browsers. Your app will be built
                  with clean, tested and scalable code.
                </span>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-sm-6 col-md-4 mb-4">
          <Link to="/services/mobile-development/">
            <div className="card shadow-sm">
              <div className="card-body p-4">
                <div className="text-center mb-3">
                  <MobileIcon />
                </div>
                <h5 className="text-uppercase">Mobile Development</h5>{" "}
                <span className="text-muted">
                  Get your app onto the most popular smartphones and tablets. We
                  develop full-stack iOS and Android applications with an
                  intense focus on user experience.
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceSelection
