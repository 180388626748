import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/common/PageHeader"
import ServiceSelection from "../components/pages/services/ServiceSelection"

const ServicesPage = () => (
  <StaticQuery
    query={graphql`
      {
        services: file(relativePath: { eq: "services.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="Services"
          keywords={[`tactic apps services`]}
          description="We offer custom built web and mobile applications along with services unique to software startups including MVPs and Idea Validation."
        />
        <PageHeader
          image={data.services.childImageSharp.gatsbyImageData}
          title="Services"
          subTitle="Custom built applications for every budget"
          overlay="darker"
        />
        <main>
          <ServiceSelection />
        </main>
      </Layout>
    )}
  />
)

export default ServicesPage
